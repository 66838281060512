import { Component, OnInit, OnDestroy } from '@angular/core';
import { IPanelLink } from './panels/type';
import { teacherPanels } from './panels/teacher';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SidepanelService } from '../sidepanel.service';
import { UserSiteContextService } from '../usersitecontext.service';
import { schoolAdminPanels } from './panels/school-admin';
import { fsaSchoolAdminPanels, fsaSchoolAdminScoreEntryPanels } from './panels/fsa-school-admin';
import { scoringLeaderPanels } from './panels/scoring-leader';
import { scoringSupervisorPanels } from './panels/scoring-supervisor';
import fsaMinistryAdminPanels from './panels/fsa-ministry-admin';
import gradMinistryAdminPanels from './panels/grad-ministry-admin';
import gradSchoolAdminPanels from './panels/grad-school-admin';
import {fsaDistrictAdminPanels, fsaDistrictAdminScoreEntryPanels} from './panels/fsa-district-admin';
import { ChatpanelService } from '../chatpanel.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../../api/auth.service';
import { AccountType } from '../../constants/account-types';
import { LangService } from '../lang.service';
import {LoginGuardService} from "../../api/login-guard.service";
import { AsmtAvailability, BcAssessmentsService } from 'src/app/bc-assessments/bc-assessments.service';
import { SECURE_LOGIN_SYS_FLAG } from 'src/app/ui-whitelabel-bc-landing/view-secure-login/model';
import { BCFSASchoolAdminView, BCGradSchoolAdminView } from 'src/app/ui-schooladmin/view-schooladmin-dashboard/data/views';
import { BCFSADistrictAdminView } from 'src/app/ui-dist-admin/view-districtadmin-dashboard/data/views';
import { BcAccountsService } from 'src/app/bc-accounts/bc-accounts.service';
import { BCFSAMinistryAdminView } from 'src/app/ui-ministryadmin/view-ministryadmin-dashboard/data/views';
import { BCGRADMinistryAdminView } from 'src/app/ui-ministryadmin/view-bcgrad-ministryadmin-dashboard/data/views';

export interface Item { name: string; }

@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit, OnDestroy {

  sidePanelOptions:IPanelLink[] = [];
  activePanelLink:IPanelLink;

  isAccountLoaded:boolean;
  displayName:string;
  displayEmail:string;
  accountType:string;
  isLanguageInitialized: boolean = false;

  private asmtAvailability: AsmtAvailability;

  private asmtAvailabilitySub: Subscription;

  private signinSub:Subscription;
  private routerSub:Subscription;
  private currSchoolGroupId: number = null;
  

  constructor(
    public whiteLabel: WhitelabelService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private sidePanel: SidepanelService,
    public lang: LangService,
    private chatPanel: ChatpanelService,
    private loginGuard: LoginGuardService,
    private userSiteContext: UserSiteContextService,
    private bcAssessments: BcAssessmentsService,
    private bcAccounts: BcAccountsService,
  ) { }

  isInMarking() {
    return this.router.url.includes('mrkg-coord') || this.router.url.includes('mrkg-lead');
  }

  viewOverrides:{[key:string]: any} = {};
  

  ngOnInit() {
    this.assignPanelLinks();
    this.signinSub = this.auth.user().subscribe(userInfo => {
      if (userInfo){
        this.isAccountLoaded = this.sidePanel.isAccountLoaded = true;
        this.displayName = [userInfo.firstName, userInfo.lastName].join(' ');
        this.displayEmail = userInfo.email;
        this.accountType = userInfo.accountType;
      }
      else{
        this.isAccountLoaded = this.sidePanel.isAccountLoaded = false;
        this.accountType = null;
      }
      this.assignPanelLinks();
    });
    // this.route.params.subscribe(params => {
    //   this.classroomId = params['classroomId'];
    //   console.log ('classroomId', this.classroomId, params)
    // });

    this.routerSub =  this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd){
        this.assignPanelLinks();
        this.checkForActivePanel(val.url);
        setTimeout(()=> { // because the router processing takes a moment to update the active classroom id from the route itself... TO DO: have a mechanism that can extract (and update) the classroom id from here
          this.checkForActivePanel(val.url);
          this.assignPanelLinks();
        }, 100);
      }
    });

    this.bcAccounts.selectedSchoolSub.subscribe(selectedSchool => {
      this.viewOverrides = this.bcAccounts.parseViewOverrides(selectedSchool);
    })

    this.lang.languageInitialized.subscribe(() => {
      this.isLanguageInitialized = true;
    });

    this.asmtAvailabilitySub = this.bcAssessments.asmtAvailabilitySub().subscribe(availability => {
      
      this.asmtAvailability = availability;
      this.assignPanelLinks();

      this.assignPanelLinks();
    });

    // this.DLSchoolSub = this.bcAccounts.getDLSchoolSub().subscribe((flag) => 
    // {
    //   if (flag != null)
    //   {
    //     this.isSchoolDL = flag;
    //   }
    // });
  }

  ngOnDestroy() {
    if (this.signinSub) {this.signinSub.unsubscribe();}
    if (this.routerSub) {this.routerSub.unsubscribe();}
  }

  isFsaSchool(){
    return this.router.url.includes('school-admin/bc-fsa');
  }
  isGradSchool(){
    return this.router.url.includes('school-admin/bc-grad');
  }

  assignPanelLinks(){
    this.sidePanelOptions = [];
    this.getUiPanels().forEach(panel => {
      this.sidePanelOptions = this.sidePanelOptions.concat(panel);
      // console.log(this.sidePanelOptions);
    });
    this.checkForActivePanel(this.router.url);

    switch(this.accountType) {
      case AccountType.BC_FSA_SCHOOL_ADMIN:
      case AccountType.BC_FSA_DIST_ADMIN:
      case AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY:
      case AccountType.BC_FSA_DIST_ADMIN_SCORE_ENTRY: {
        this.sidePanelOptions.map(option => {
          if ([
            BCFSASchoolAdminView.BC_FSA_SCORE_ENTRY,
            BCFSASchoolAdminView.BC_FSA_FINAL_REPORT,
            BCFSASchoolAdminView.BC_FSA_SESSION_PROGRESS_REPORTS,
            BCFSADistrictAdminView.BC_FSA_FINAL_RESULTS,
            BCFSADistrictAdminView.BC_FSA_SCORE_ENTRY,
            BCFSADistrictAdminView.BC_FSA_PROGRESS_REPORTS,
          ].includes(option.id)) {
            option.disabled = !this.asmtAvailability[SECURE_LOGIN_SYS_FLAG.FSA];
          }
        })
      }
    }
  }

  checkMarkingRoles(){
    return !!this.auth.myActiveMarkingRole(0, true);
  }

  getUiPanels(){
    let ui = this.accountType;
    if(this.isFsaSchool()) ui = AccountType.BC_FSA_SCHOOL_ADMIN;
    if(this.isGradSchool()) ui = AccountType.BC_GRAD_SCHOOL_ADMIN;
    switch(ui){
      case AccountType.EDUCATOR:        return [teacherPanels]
      case AccountType.SCHOOL_ADMIN:    return [schoolAdminPanels];
      case AccountType.BC_FSA_SCHOOL_ADMIN:    return [fsaSchoolAdminPanels];
      case AccountType.BC_FSA_SCHOOL_ADMIN_SCORE_ENTRY:    return [fsaSchoolAdminScoreEntryPanels];
      case AccountType.BC_FSA_DIST_ADMIN_SCORE_ENTRY:     return [fsaDistrictAdminScoreEntryPanels];
      case AccountType.SCOR_LEAD:    return [scoringLeaderPanels];
      case AccountType.SCOR_SUPR:    return [scoringSupervisorPanels];
      case AccountType.BC_GRAD_MINISTRY_ADMIN:  {
        return [gradMinistryAdminPanels.map(view => {
          if(view.id == BCGRADMinistryAdminView.MARKING) view.disabled = !this.checkMarkingRoles();
          return Object({...view});
        })];
      }
      case AccountType.BC_GRAD_MINISTRY_ADMIN_SPECIAL_CASE: return [gradMinistryAdminPanels.filter(view => view.id == 'aegrotat_disqualification')];
      case AccountType.BC_GRAD_SCHOOL_ADMIN: return [gradSchoolAdminPanels];
      case AccountType.BC_FSA_MINISTRY_ADMIN: {
        return [fsaMinistryAdminPanels.map(view => {
          if(view.id == BCFSAMinistryAdminView.MONITORING_MARKING) view.disabled = !this.checkMarkingRoles();
          return Object({...view});
        })];
      }
      case AccountType.BC_FSA_DIST_ADMIN: return [fsaDistrictAdminPanels];
      default: return [];
    }
  }

  isOptionHidden(option:IPanelLink)  {
    
    const override = this.viewOverrides[option.id]
    if (override){
      if (override.isHidden){
        return true;
      }
    }
    else if (option.isHidden){
      return true;
    }
    
    // console.log(option, this.isSchoolDL);
    // if (option.id === BCGradSchoolAdminView.BC_GRAD_DL_CENTER_REPORT && !this.isSchoolDL)
    // {
    //   return true;
    // }

    if (option.requiresClassroom) {
      if (!this.userSiteContext.hasActiveClassroom())
      {
        return true;
      }
    }
    return false;
  }

  openSupportChat(){
    this.chatPanel.expand();
  }

  checkForActivePanel(currentUrl:string){
    this.activePanelLink = null;
    this.sidePanelOptions.forEach( panelLink =>{
      const routeParts:string[] = this.processRouteParts(panelLink.routerLink);
      if (routeParts[1]){
        routeParts[1] = this.lang.c(); // temp, hardcoding the lang
      }
      let url = routeParts.join('/');
      url.split(':classroomId').join(this.sidePanel.classroomId); // todo: should be happening in processRouteParts
      panelLink.routerLinkProcessed = url;
      if (currentUrl.indexOf(url) === 0){
        this.activePanelLink = panelLink;
      }
    })
  }
  
  logout() {
    this.auth
      .logout()
      .then( r => 
        {
          if (this.whiteLabel.getSiteFlag('IS_BCED') || this.whiteLabel.getSiteFlag('IS_NWT'))
          {
            this.router.navigate(['/en/bced-landing/admin']);
          }
          else
          {
            this.router.navigate(['/en/login-router-st']);
          }
          setTimeout(() => 
          {
            window.location.reload();
          }, 300);
      });
  }


  // getUsername(){
  //   return this.auth.getUsername();
  // }

  // getAccountTypeDisplayName(){
  //   switch(this.auth.getUi()){
  //     case UserUi.TEACHER: return 'Teacher';
  //     case UserUi.STUDENT: return 'Student';
  //     case UserUi.PARENT: return 'Parent';
  //     case UserUi.ADMIN: return 'Administrator';
  //     case UserUi.TRIAL_COORD: return 'Trial Coordinator';
  //     case UserUi.DISTRICT_COORD: return 'District Coordinator';
  //   }
  // }

  isUserCoordinator(){

  }
  // isUserTeacher(){
  //   switch(this.auth.getUi()){
  //     case UserUi.TEACHER:
  //     case UserUi.TRIAL_COORD:
  //     case UserUi.ADMIN:
  //       return true;
  //   }
  //   return false;
  // }
  // isUserParent(){
  //   return this.auth.getUi() === UserUi.PARENT;
  // }
  // isUserAdministrator(){
  //   return this.auth.getUi() === UserUi.ADMIN;
  // }

  // logout(){
  //   this.auth.signOut();
  //   this.router.navigate(['/']);
  // }

  // getUserPhotoUrl(){
  //   return this.auth.getPhotoUrl();
  // }

  isAccountDropupActive() {
    return this.sidePanel.getAccountDropupActive();
  }

  toggleAccountDropdown(){
    this.sidePanel.toggleAccountDropdown();
  }

  // getUserDisplayname(){
  //   return this.auth.getDisplayName()
  // }

  isSidePanelExpanded():boolean{
    return this.sidePanel.getExpandedStatus();
  }

  expandSidePanel(){
    this.sidePanel.expand();
  }
  collapseSidePanel(){
    this.sidePanel.unexpand();
  }

  isRouteActive(panelLink:IPanelLink) {
    if(panelLink === this.activePanelLink){
      return true;
    }
    return false;
  }

  processRouteParts(routeTemplate:string):string[]{
    let routeParts:string[] = routeTemplate.split('/');
    routeParts.forEach( (routePart,i) => {
      if (i > 0){
        routeParts[i] = this.userSiteContext.processRoutePart(routePart);
      }
    })
    return routeParts;
  }

  navToOptionRoute(option:IPanelLink){
    // console.log('navToOptionRoute', option.requiresClassroom, this.userSiteContext.hasActiveClassroom())
    // return;
    this.navToRoute(option.routerLink);
  }

  navToRoute(routeTemplate:string){
    let routeParts:string[] = this.processRouteParts(routeTemplate);
    this.router.navigate(routeParts);
  }

  pageUnavailable(e: Event, markingMessage = false) {
    if (e) {
      (e.target as HTMLElement).blur();
    }
    if(markingMessage) this.loginGuard.quickPopup('Your account does not have access to this view at this time');
    else this.loginGuard.quickPopup(this.lang.tra('ma_am_current_pageUnavailable'))
  }

  navItemClicked(e: Event, item: IPanelLink){
    console.log(item);
    const queryParams = this.route.snapshot.queryParams;
    if(item.id == BCFSAMinistryAdminView.MONITORING_MARKING || item.id == BCGRADMinistryAdminView.MARKING) {
      if(item.disabled) this.pageUnavailable(e, true);
      else this.router.navigate([`/${this.lang.c()}/${this.auth.myActiveMarkingRole(0, true)}/dashboard`], {relativeTo: this.route,
          queryParams})
    }else{
      if (!item.disabled) this.router.navigate([item.routerLinkProcessed], {relativeTo: this.route,
          queryParams})
      else this.pageUnavailable(e)
    }
  }
}
